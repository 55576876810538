/* styles.module.css */

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    margin: 0 auto;
    padding-top: 50px;
    font-family: Arial, sans-serif;
  }
  
  .form input[type='text'],
  .form input[type='email'],
  .form input[type='password'] {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .form button[type='submit'] {
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    border: none;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .form button[type='submit']:hover {
    background-color: #0056b3;
  }
  