/* Download.module.css */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Set the height of the container to 100% of the viewport height */
}

.downloadcard {
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    text-align: center;
    /* Optional: Align text center within the card */
}

/* Define other styles as needed */
/* Download.module.css */
/* ... (other styles) */

.fileName {
    margin-top: 10px;
    font-weight: bold;
    color: #333;
    /* Change the color as desired */
}

.uploadedBy {
    margin-top: 10px;
    color: #666;
    /* Change the color as desired */
}

.button {
    margin-top: 20px;
    padding: 7px;
    border-radius: 4px;
    border: none;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #0056b3;
}