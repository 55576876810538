/* styles.module.css */

.fileUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin: 0 auto;
  padding-top: 50px;
  font-family: Arial, sans-serif;
}

.fileUpload input[type='file'] {
  margin-bottom: 15px;
}

.fileUpload button {
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.fileUpload button:hover {
  background-color: #0056b3;
}

.fileInputLabel {
  padding: 30px 40px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  text-align: center;
  margin-bottom: 15px;
}

.fileInputLabel:hover {
  background-color: #f7f7f7;
}

.progressBar {
  width: 100%;
  /* Set your desired width */
  height: 20px;
  /* Set your desired height */
  background-color: #ddd;
  /* Background color of the progress bar */
  border-radius: 5px;
  /* Border radius for rounded corners */
  overflow: hidden;
  /* Hide overflowing progress */

  /* Optional: Add more styles for the overall container */
}

.progressFill {
  height: 100%;
  background-color: #007bff;
  /* Color for the filled progress */
  transition: width 0.3s ease-in-out;
  /* Optional: Add transition effect for width changes */
}