/* styles.module.css */

.horizontalList {
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    background-color: #333;
  }
  
  .horizontalList li {
    margin: 0 10px;
  }
  
  .horizontalList li a {
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
  }
  
  .horizontalList li a:hover {
    background-color: #555;
  }
  