/* In your CSS module file */

/* Styles for larger screens */
.filelistdiv {
    text-align: center;
    padding: 20px;
}

.filelist {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.file {
    display: flex;
    border: 1px solid rebeccapurple;
    border-radius: 5px;
    margin: 4px;
    padding: 8px;
    width: 50%;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.file:hover {
    transform: translateY(-5px);
}

.file > p {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
    max-width: 70%;
}

.filebtn {
    width: 40%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.btn {
    padding: 3px;
}

/* Styles for smaller screens (mobile devices) */
@media (max-width: 768px) {
    .file {
        width: 80%; /* Adjust width for smaller screens */
    }

    .file > p {
        max-width: 50%; /* Adjust max-width for smaller screens */
    }

    .filebtn {
        width: 60%; /* Adjust button container width for smaller screens */
    }
}

.pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .pagination button {
    padding: 8px 12px;
    margin: 0 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    color: #333;
    border-radius: 4px;
    outline: none;
  }
  
  .pagination button:hover {
    background-color: #e0e0e0;
  }